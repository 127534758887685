import React from 'react';
import './App.css';
import Chat from './Chat';
import '@progress/kendo-theme-material/dist/all.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/fontmell">
            <Chat name="Fontmell" logoUrl="https://coral-dogfish-8160.twil.io/assets/Final%2BMarque%2BBLK.jpg" rotate={true} pubName="The Fontmell" badge="https://bit.ly/3gJ7Fyi"/>
          </Route>
          <Route exact path="/zingarms">
            <Chat name="ZingPub" logoUrl="https://coral-warthog-8894.twil.io/assets/ZingLogo.png" rotate={true} pubName="The Zing's Arms" badge="https://bit.ly/3f9h568"/>
          </Route>
          <Route exact path="/bullswan">
            <Chat name="BullSwan" logoUrl="https://ruby-cheetah-3532.twil.io/assets/bullswan-logo.png" rotate={false} pubName="The Bull & Swan" badge="https://bit.ly/2ZfmHWZ"/>
          </Route>
          <Route exact path="/williamcecil">
            <Chat name="WilliamCecil" logoUrl="https://charcoal-otter-7307.twil.io/assets/wc-logo.png" rotate={false} pubName="The William Cecil" badge="https://bit.ly/3ecSpIs"/>
          </Route>
          <Route exact path="/oddfellows">
            <Chat name="OddFellows" logoUrl="https://pistachio-pony-5040.twil.io/assets/odd_fellows.png" rotate={false} pubName="Odd Fellows" badge="https://bit.ly/2P4vFAl"/>
          </Route>
          <Route path="/" component={() => {
            window.location.href = 'https://zing.dev/safe-2-serve/'; 
            return null;
          }}>
          </Route>
        </Switch>
      </div>
    </Router>

  );
}

export default App;
