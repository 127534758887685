import React, { useState, useEffect } from 'react';
import { Chat as ChatUI } from '@progress/kendo-react-conversational-ui';
import * as TwilioChat from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import Message from './models/Message';

export interface ChatProps {
    name: string;
    logoUrl: string;
    pubName: string;
    badge:string;
    rotate: boolean;
}

export default function Chat(props: ChatProps){
    const [messages, setMessages] = useState<Message[]>([]);
    const [accessToken, setAccessToken] = useState('');
    const [channel, setChannel] = useState<Channel | null>(null);

    useEffect(() => {
        if(accessToken.length === 0){
            getAccess();
        }else{
            initClient();
        }
    },[accessToken])

    async function getAccess(){
        const res = await fetch(`https://safeservicefunctions.azurewebsites.net/api/GetAccessToken/${props.name}`,{
            headers: {
                "x-functions-key": 'HwsoKhdDqpAtvUTPSxKhL55VcjPxP4r4RMyOtUByqeCUE4r9oFN9VQ=='
            }
        });
        if(res.ok){
            setAccessToken((await res.json()).token);
        }
    }

    async function initClient(){
        const client = await TwilioChat.Client.create(accessToken);
        const channel = await client.createChannel();
        await fetch(`https://safeservicefunctions.azurewebsites.net/api/StartAutoPilot/${props.name}/${channel.sid}`,{
            headers: {
                "x-functions-key": 'lRuwUx59VDMA8ZosjhCySmhyeSpKKwNVuVa9XUG9o277HwJiBoyU0Q=='
            }
        });
        setChannel(await channel.join());
        channel.on('messageAdded', (message) => {
            if(message.state.author === 'system'){
                setMessages(messages => [...messages,
                    {
                        id:1,
                        text: message.state.body.replace(props.badge,''),
                        author: {
                            id:0,
                            name:props.pubName
                        }
                    },
                ]);
                    if(message.state.body.includes(props.badge)){
                    setMessages(messages => [...messages,
                        {
                            id:1,
                            attachments: [
                                {
                                    content: props.badge,
                                    contentType: 'image/png',
                                    height:10
                                }
                            ],
                            text: '',
                            author: {
                                id:0,
                                name:'Pub'
                            }
                        },
                    ]);
                }
            }
        })
        channel.sendMessage('I want a drink!');
    }


    async function sendMessage(e:any){
        if(channel){
            setMessages(messages => [...messages,
                {
                    id:1,
                    text: e.message.text,
                    author: {
                        id:1,
                        name:'Me'
                    }
                },
            ])
            channel.sendMessage(e.message.text);
        }
    }

    function attachmentTemplate(props:any){
        let attachment = props.item;
        if (attachment.contentType.match('^image/')) {
            return (
                <a target="_blank" href={attachment.content} rel="noopener noreferrer">
                    <img src={attachment.content} height="130" alt="safe to serve" />
                </a>
            );
        }
    }
    
    return(
        <div style={{margin: 10}}>
            {messages.length === 0 ? 
                <img className={props.rotate ? "App-logo" : ''} src={props.logoUrl} />
            :
                <ChatUI
                    user={{id:1}}
                    width={300}
                    messages={messages}
                    onMessageSend={sendMessage}
                    placeholder={'Enter your message'}
                    attachmentTemplate={attachmentTemplate}
                />
            }
        </div>
    )
}